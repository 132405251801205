import React from "react";
import { formatPrice, toCommas } from "src/utils/CommomFunction";
import { CURRENTCY } from "utils/format";
export const STATUS_COLUMNS = [
  {
    title: "pending",
    status: "pending",
  },
  {
    title: "received",
    status: "received",
  },
  {
    title: "cooking",
    status: "cooking",
  },
  {
    title: "ready_to_ship",
    status: "ready_to_ship",
  },
  {
    title: "shipping",
    status: "shipping",
  },
];

export const columns = (t) => [
  {
    title: t("name"),
    dataIndex: "name",
    width: 380,
    render: (name, item) => {
      return (
        <div
          className="order-name"
          style={{
            paddingBottom: item.options.length === 0 && "34px",
          }}
        >
          <h3>{name}</h3>
          {item?.options &&
            item?.options.map((itemOption, index) => (
              <div key={index}>
                <h5 className="color-orange">Option</h5>
                <p className="color-green-color-1">
                  {itemOption?.pos_name ?? itemOption?.name} x{itemOption?.qty}
                </p>
                <p className="color-green-color-1">
                  + {formatPrice(itemOption?.price * itemOption?.qty)}
                  {CURRENTCY}
                </p>
              </div>
            ))}
        </div>
      );
    },
  },
  {
    title: t("price"),
    dataIndex: "price",
    align: "right",
    width: 150,
    render: (price) => <div className="order-price">{formatPrice(price)}</div>,
  },
  {
    title: t("count"),
    dataIndex: "count",
    width: 150,
    align: "center",
    render: (count) => <div className="order-count">x{count}</div>,
  },
  {
    title: t("total"),
    dataIndex: "total",
    align: "right",
    render: (total, item) => (
      <div className="order-price">
        {toCommas(parseFloat(total) * parseInt(item?.count))}
        {CURRENTCY}
      </div>
    ),
  },
];
