import { CloseCircleOutlined } from "@ant-design/icons";
import { Modal, Radio } from "antd";
import React, { useState, useEffect } from "react";
import ButtonSubmit from "../Button/ButtonSubmit";
import { customNotification } from "src/utils/CommomFunction";
import { apiGetShipperList, apiAssignShipper } from "../../apis/Shipper";
import { useTranslation } from "react-i18next";

const { info } = Modal;

function ChangeDeliveryStaffModal(props) {
  const {
    closeModalReadyShip,
    isShowModalChangeStaff,
    closeModalChangeStaff,
    dataOrder,
    openCurrentModal,
  } = props;
  const [shipperList, setShipperList] = useState();
  const [selectedShipper, setSelectedShipper] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (dataOrder?.shipper_info) {
      setSelectedShipper(dataOrder.shipper_info[0].id);
    }
  }, [dataOrder]);

  useEffect(() => {
    if (isShowModalChangeStaff) {
      apiGetShipperList().then((res) => {
        if (!res.errors && res.data) {
          setShipperList(res.data.getShipperList.list);
        }
      });
    }
  }, [dataOrder, isShowModalChangeStaff]);

  const handleConfirm = () => {
    if (selectedShipper) {
      setLoadingButton(true);
      apiAssignShipper({
        shipperId: selectedShipper,
        orderId: dataOrder.id,
      }).then((res) => {
        setLoadingButton(false);
        if (!res.errors && res.data) {
          apiGetShipperList().then((res) => {
            if (!res.errors && res.data) {
              setShipperList(res.data.getShipperList.list);
            }
          });
          customNotification("success", t("shipperAlertChange"));
          closeModalChangeStaff();
          closeModalReadyShip();
          return;
        }
      });
      return;
    }
    closeModalChangeStaff();
    openCurrentModal();
  };

  const renderTitleModal = () => {
    return (
      <>
        <div className="title-left" key={dataOrder?.id}>
          <h3>{t("changeShipperTitle")}</h3>
          <h5>
            {t("pleaseSelectShipper")}
            <span></span>
          </h5>
        </div>
      </>
    );
  };

  const handleSelectShipper = (item) => {
    if (item?.orders?.length >= 2) {
      return info({
        icon: <></>,
        content: <span>{t("shipper2order")}</span>,
        onOk() {},
        onCancel() {},
      });
    }
    setSelectedShipper(item.id);
  };

  return (
    <Modal
      centered={true}
      destroyOnClose={true}
      className="modal-changeStaff"
      title={renderTitleModal()}
      visible={isShowModalChangeStaff}
      width={560}
      onCancel={closeModalChangeStaff}
      footer={[
        <span onClick={handleConfirm} key={dataOrder?.id}>
          <ButtonSubmit
            loadingButton={loadingButton}
            title={t("confirmText")}
            color="#E31837"
            key={1}
          />
        </span>,
        <button
          key="huy"
          className="button-destroy"
          onClick={() => {
            if (dataOrder?.shipper_info) {
              setSelectedShipper(dataOrder.shipper_info[0].id);
            }
            closeModalChangeStaff();
            openCurrentModal();
          }}
        >
          <CloseCircleOutlined />
          {t("cancelText")}
        </button>,
      ]}
    >
      <div className="modal-listStaff">
        {shipperList?.map((item, index) => {
          return (
            <div
              className="staff-item"
              key={item?.id}
              onClick={() => handleSelectShipper(item)}
            >
              <div className="staff-left">
                <h3>{`${item?.last_name} ${item?.first_name}`}</h3>
                <h5>
                  {t("totalOrderShipping")}: {item?.orders?.length}
                </h5>
              </div>
              <span>
                <Radio checked={selectedShipper === item?.id}></Radio>
              </span>
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

export default ChangeDeliveryStaffModal;
