import { Button, Col, Spin, Modal, Radio, Row, Table } from "antd";
import React, { Fragment } from "react";
import ButtonTime from "../Button/ButtonTime";
import Icon from "../Button/Icon";
import iconReadyShip from "../../assets/ready_ship.png";
import TotalOder from "../TotalOder";
import {
  formatPrice,
  convertOrderType,
  translatePaymentMethod,
  toCommas,
} from "src/utils/CommomFunction";
import moment from "moment";
import { useTranslation } from "react-i18next";
const CURRENTCY = "USD";
export const columns = [
  {
    title: "name",
    dataIndex: "name",
    width: 200,
    render: (name, item) => {
      return (
        <div
          className="order-name"
          style={{
            paddingBottom: item.options.length === 0 && "34px",
          }}
        >
          <h3>{name}</h3>
          {item?.options &&
            item?.options.map((itemOption, index) => (
              <div key={index}>
                <h5 className="color-orange">Option</h5>
                <p className="color-green-color-1">
                  {itemOption?.name} x{itemOption?.qty}
                </p>
                <p className="color-green-color-1">
                  + {formatPrice(itemOption?.price * itemOption?.qty)}{" "}
                  {CURRENTCY}
                </p>
              </div>
            ))}
        </div>
      );
    },
  },
  {
    title: "price",
    dataIndex: "price",
    align: "right",
    width: 150,
    render: (price) => <div className="order-price">{formatPrice(price)}</div>,
  },
  {
    title: "count",
    dataIndex: "count",
    width: 150,
    align: "center",
    render: (count) => <div className="order-count">x{count}</div>,
  },
  {
    title: "total",
    dataIndex: "total",
    align: "right",
    render: (total, item) => (
      <div className="order-price">
        {toCommas(parseFloat(total) * parseInt(item?.count))}
        {CURRENTCY}
      </div>
    ),
  },
];

export default function ModalCooking({
  title = () => {},
  isShowModalCooking,
  closeModalCooking,
  detailOrder,
  dataOrderModal,
  timeToEnd = () => {},
  shipperList,
  handleSelectShipper = () => {},
  selectedShipper,
  handleSubmitReadyToShip,
  loadingButton,
}) {
  const { t } = useTranslation();
  const convertDetailOrder = () => {
    if (detailOrder) {
      let items = detailOrder?.items ?? [];

      items = items.sort(function (a, b) {
        let c = a?.price;
        let d = b?.price;
        return c - d;
      });
      return items.map((item, index) => ({
        ...item,
        key: item,
        name: item?.name,
        count: item?.qty,
        price: item?.price + CURRENTCY,
        total: item?.price + CURRENTCY,
      }));
    }
    return [];
  };

  return (
    <Modal
      key="cooking"
      centered={true}
      destroyOnClose={true}
      className="modal-cooking"
      title={title}
      visible={isShowModalCooking}
      width={1080}
      onCancel={closeModalCooking}
      footer={null}
    >
      {detailOrder && detailOrder?.id === dataOrderModal?.id ? (
        <>
          <div className="modal-info" key={dataOrderModal?.id}>
            <div className="modal-info-left" style={{ width: "100%" }}>
              {detailOrder?.shipping_method === "Giao hàng tận nơi" && (
                <>
                  <div className="modal-info-left-text">
                    <Icon status={true} />
                    <div className="modal-info-desc" style={{ width: "100%" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <span>
                          <strong>Hình thức đặt hàng</strong> <br />
                          Giao hàng tận nơi
                        </span>{" "}
                        <ButtonTime dataOrder={detailOrder} />
                      </div>
                      <span>
                        {`Phương thức thanh toán: `}
                        <span style={{ fontWeight: "bold", color: "#0A8D87" }}>
                          {translatePaymentMethod(
                            detailOrder?.payment_method,
                            t
                          )}
                        </span>
                      </span>
                      <p className="modal-info-time">
                        Nhận hàng dự kiến:{" "}
                        {!(
                          dataOrderModal?.pickup_date &&
                          dataOrderModal?.pickup_time
                        ) && timeToEnd(dataOrderModal)}
                      </p>
                      <p className="modal-info-time">
                        {dataOrderModal?.pickup_date &&
                          dataOrderModal?.pickup_time &&
                          timeToEnd(dataOrderModal)}
                      </p>
                      <strong>
                        Kênh đặt hàng:{" "}
                        {convertOrderType(detailOrder?.order_type)}
                      </strong>
                    </div>
                  </div>
                </>
              )}
              {detailOrder?.shipping_method === "Nhận tại cửa hàng" && (
                <>
                  <div className="modal-info-left-text">
                    <Icon status={false} />
                    <div className="modal-info-desc">
                      <p>Hình thức đặt hàng</p>
                      <span>Hẹn lấy tại cửa hàng</span>
                      <br />
                      <span>
                        {`Phương thức thanh toán: `}
                        <span style={{ fontWeight: "bold", color: "#0A8D87" }}>
                          {translatePaymentMethod(
                            detailOrder?.payment_method,
                            t
                          )}
                        </span>
                      </span>
                      <p className="modal-info-time">
                        Nhận hàng dự kiến:{" "}
                        {!(
                          dataOrderModal?.pickup_date &&
                          dataOrderModal?.pickup_time
                        ) && timeToEnd()}
                      </p>
                      <p className="modal-info-time">
                        {dataOrderModal?.pickup_date &&
                          dataOrderModal?.pickup_time &&
                          timeToEnd()}
                      </p>
                      <strong>
                        Kênh đặt hàng:{" "}
                        {convertOrderType(detailOrder?.order_type)}
                      </strong>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="modal-info-right"
              style={{ overflow: "scroll", maxHeight: 115 }}
            >
              <h5>
                {`${detailOrder?.firstname} ${detailOrder?.lastname}`} -{" "}
                {detailOrder?.customer_phone}
              </h5>
              <p>
                {detailOrder?.shipping_method === "Nhận tại cửa hàng" ? (
                  <b>Hẹn lấy tại cửa hàng</b>
                ) : (
                  <>
                    <b>Giao đến:</b> {detailOrder?.phone} -{" "}
                    <span>{detailOrder?.address}</span>
                    {detailOrder?.address_note && (
                      <Fragment>
                        <br />
                        <b style={{ color: "#E31837" }}>Ghi chú:</b>
                        <span style={{ color: "#E31837" }}>
                          {detailOrder?.address_note}
                        </span>
                      </Fragment>
                    )}
                  </>
                )}
              </p>
            </div>
          </div>

          <div className="modal-container-box">
            <Row gutter={[24, 24]}>
              <Col span={16} className="modal-content-left">
                <div className="modal-remark">
                  <h3>
                    {t("itemDetail")}{" "}
                    <span>
                      {detailOrder?.use_plastic !== 0 && t("plasticEating")}
                    </span>
                  </h3>
                  <h5
                    style={{
                      maxHeight: 30,
                      overflowY: "scroll",
                      color: "#E31837",
                    }}
                  >
                    <b>{t("note")}:</b> {detailOrder?.note?.split("&&")[0]}
                  </h5>
                  <h5
                    style={{
                      maxHeight: 30,
                      overflowY: "scroll",
                      color: "#E31837",
                    }}
                  >
                    {" "}
                    {detailOrder?.note?.split("&&")[1]}
                  </h5>
                </div>
                <div className="modal-order">
                  <Table
                    className="table-order table-cooking"
                    columns={columns}
                    dataSource={convertDetailOrder()}
                    pagination={false}
                    scroll={{ y: 390 }}
                    summary={() => {
                      return <TotalOder detailOrder={detailOrder} />;
                    }}
                  />
                </div>
              </Col>
              <Col span={8} className="modal-content-right">
                <div className="modal-remark ">
                  <h3>CHỌN NHÂN VIÊN GIAO HÀNG</h3>
                  <h5>Vui lòng chọn nhân viên để giao đơn hàng này.</h5>
                </div>
                {dataOrderModal?.shipping_method === "Giao hàng tận nơi" && (
                  <div className="modal-listStaff">
                    {shipperList?.map((item, index) => {
                      return (
                        <div
                          className="staff-item"
                          key={`shipper ${item?.id}${index}`}
                          onClick={() => handleSelectShipper(item)}
                        >
                          <div className="staff-left">
                            <h3>{`${item?.last_name} ${item?.first_name}`}</h3>
                            <h5>Tổng đơn đang giao: {item?.orders.length}</h5>
                          </div>
                          <span>
                            <Radio
                              checked={selectedShipper === item.id}
                            ></Radio>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                )}
                <Button
                  onClick={handleSubmitReadyToShip}
                  className="button-readyship"
                  loading={loadingButton}
                  key="5"
                  icon={
                    <img
                      src={iconReadyShip}
                      alt=""
                      style={{ marginRight: "5px" }}
                    ></img>
                  }
                >
                  {!loadingButton && "Sẵn sàng giao"}
                </Button>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <Row justify="center">
          <Spin spinning></Spin>
        </Row>
      )}
    </Modal>
  );
}
